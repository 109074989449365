$(document).ready(function(){
    var $html = $('html');
    var $body = $('body');
    var $button = $('.nav--trigger');
    $button.on('click', function(){
        if($body.hasClass('sub-menu-open')){
            $('.sub-menu-open').removeClass('sub-menu-open');
        } else {
            if($body.hasClass('menu-open')){
                $body.removeClass('menu-open');
            } else {
                $body.addClass('menu-open');
                $('.sub-menu-open').removeClass('sub-menu-open');
            }
        }
    });
    $(document).on('click', function(e){
        var isTouch = false;
        if($html.hasClass('touchevents'))isTouch = true ;
        var isNavBurger = false;
        if($button.is(':visible'))isNavBurger = true ;
        if($(e.target).hasClass('menu-open')){
            $body.removeClass('menu-open');
            $('.sub-menu-open').removeClass('sub-menu-open');
        }
        if(isTouch && !isNavBurger){
            if(e.target.tagName !== 'A' && $('li.open').length){
                $menuItemsHasChildren.removeClass('open');
            }
        }
    });
    var $menuItemsHasChildren = $('li.menu-item-has-children');
    var $menuItemsHasChildrenLink = $('> a',$menuItemsHasChildren);
    $menuItemsHasChildrenLink.append('<span class="nav--item--sub-indicator"><i class="fas fa-chevron-down"></i></span>');
    $menuItemsHasChildrenLink.on('click', function(e){
        var isTouch = false;
        if($html.hasClass('touchevents'))isTouch = true ;
        var isNavBurger = false;
        if($button.is(':visible'))isNavBurger = true ;
        var $li = $(this).parent();
        var $ul = $li.parent();
        if(isNavBurger){
            if(!$li.hasClass('sub-menu-open')){
                $body.addClass('sub-menu-open');
                $ul.addClass('sub-menu-open');
                $li.addClass('sub-menu-open');
                e.preventDefault();
            }
        } else if(isTouch){
            if(!$li.hasClass('open')){
                $menuItemsHasChildren.removeClass('open');
                $li.addClass('open');
                e.preventDefault();
            }
        }
    });
});