$(document).ready(function() {
    var $htmlBody = $('html, body');
    var $body = $('body');
    var $header = $('.header');
    var $window = $(window);
    var $fcNav = $('.fc--nav');
    var $gmap = $('.gmap--leaf');
    var $prevArrow = '<img src="/wp-content/themes/orvia/assets/dest/img/pictos/picto-arrow.svg" alt="">';
    var $nextArrow = '<img src="/wp-content/themes/orvia/assets/dest/img/pictos/picto-arrow.svg" alt="">';

    var $scroll = $(window).scrollTop();
    scrollHeader($scroll);
    $(window).scroll(function() {
        $scroll = $(window).scrollTop();
        scrollHeader($scroll);
    });

    function scrollHeader($scroll) {
        if ($scroll) {
            $header.addClass('header--scrolled');
            if ($fcNav.length) {
                $fcNav.addClass('fc--nav--scrolled');
            }
        } else {
            $header.removeClass('header--scrolled');
            if ($fcNav.length) {
                $fcNav.removeClass('fc--nav--scrolled');
            }
        }
    }

    if ('anim' in sessionStorage) {
        if (sessionStorage.getItem('anim')) {
            hideIntro();
        }
    }

    // animation intro
    var $homeIntro = $('.home--intro');
    if ($homeIntro.length) {
        if ($homeIntro.hasClass('hidden')) {
            $body.removeClass('has-intro');
        }
        $('.home--intro--animation svg', $homeIntro).on('animationend', function () {
            hideIntro();
        });
        $('.home--intro--skip', $homeIntro).on('click', function () {
            hideIntro();
        });
    }

    function hideIntro() {
        var $homeIntro = $('.home--intro');
        $homeIntro.addClass('hidden');
        $body.removeClass('has-intro');
        sessionStorage.setItem('anim', 'hide');
    }

    var $presentation = $('.push--presentation');
    if ($presentation.length) {
        var $presentationSlide = $('.push--presentation--slide--items', $presentation);
        if ($presentationSlide.length) {
            var $infinite = false;
            if ($presentationSlide.length > 1) {
                $infinite = true;
            }
            $presentationSlide.slick({
                accessibility: false,
                arrows: false,
                autoplay: true,
                centerMode: true,
                centerPadding: 0,
                fade: true,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: $infinite,
                slidesToScroll: 1,
                slidesToShow: 1,
                speed: 3000,
            });
        }

        var $presentationTerms = $('.push--presentation--terms', $presentation);
        if ($presentationTerms.length) {
            var $presentationTermsSlide = $('.push--presentation--terms--items', $presentationTerms);
            var $presentationTermsNav = $('.push--presentation--terms--nav', $presentationTerms);
            $presentationTermsSlide.slick({
                accessibility: false,
                arrows: true,
                prevArrow: '<button class="push--presentation--terms--arrow push--presentation--terms--arrow--prev"><i class="fas fa-chevron-left"></i></button>',
                nextArrow: '<button class="push--presentation--terms--arrow push--presentation--terms--arrow--next"><i class="fas fa-chevron-right"></i></button>',
                appendArrows: $presentationTermsNav,
                centerMode: true,
                centerPadding: 0,
                fade: false,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                speed: 1000,
            });
        }
    }

    var $keyFigure = $('.push--key-figure');
    if ($keyFigure.length) {
        if (!$keyFigure.hasClass('animated')) {
            animCounter('.push--key-figure--key--number');
            $keyFigure.addClass('animated');
        }
    }

    var $eventsItems = $('.events--items');
    if ($eventsItems.length) {
        var $appendArrowsDots = $('.events--arrows-dots');
        var $archivePage = $('.archive--body');
        var $homePage = $('body.home');
        var $arrows = false;
        var $dots = false;
        if ($homePage.length) {
            $arrows = true;
            $dots = true;
        }
        if (($archivePage.length && window.matchMedia("(min-width: 1025px)").matches) || $homePage.length) {
            $eventsItems.slick({
                accessibility: false,
                arrows: $arrows,
                prevArrow: '<button class="events--arrow events--arrow--prev">' + $prevArrow + '</button>',
                nextArrow: '<button class="events--arrow events--arrow--next">' + $nextArrow + '</button>',
                appendArrows: $appendArrowsDots,
                centerMode: true,
                centerPadding: 0,
                fade: false,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            dots: $dots,
                            appendDots: $appendArrowsDots,
                            dotsClass: 'events--dots',
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }
    }

    var $homeMap = $('.push--implantations--map');
    if ($homeMap.length) {
        var $pins = $('.tooltip', $homeMap);
        if ($pins.length) {
            $pins.on('mouseover', function () {
                var $pinID = $(this).data('map-id');
                $('.map--location--marker[data-map-id!="' + $pinID + '"]').removeClass('hover');
                $('.tooltip--item[data-tooltip-id!="' + $pinID + '"]').fadeOut();
                $(this).addClass('hover');
                $('.tooltip--item[data-tooltip-id="' + $pinID + '"]').fadeIn();
            });
            $homeMap.on('mouseleave', function () {
                $('.map--location--marker').removeClass('hover');
                $('.tooltip--item').fadeOut();
            });
        }
    }

    if(!$('body').hasClass('single-actualite')){
        var $fc = $('.fc');
        if ($fc.length) {
            var $fcH2 = $('h2', $fc);
            if ($fcH2.length > 1) {
                $fcNav.removeClass('hidden');
                $fcH2.each(function () {
                    var $text = $(this).text();
                    var $fcColRow = $(this).closest('.fc--col--row');
                    if (!$fcColRow.length) {
                        $fcColRow = $(this).closest('.fc--intro');
                    }
                    var $target = $fcColRow.attr('data-target');
                    $('.fc--nav--items').append('<li class="fc--nav--item" data-target="' + $target + '">' + $text + '</li>');
                    var $element = $('.fc--nav--item[data-target="' + $target + '"]');
                    var $min = parseInt($fcColRow.offset().top, 10);
                    var $max = parseInt($min + $fcColRow.closest('.fc--row').outerHeight() - 2, 10);
                    $(this).scrollspy({
                        min: $min,
                        max: $max,
                        buffer: $window.height() / 2,
                        onEnter: function onEnter() {
                            $element.addClass('active');
                        },
                        onLeave: function onLeave() {
                            $element.removeClass('active');
                        }
                    });
                });
            } else {
                $fcNav.addClass('hidden');
            }

            var $flexRows = $('.fc--row');
            if ($flexRows.length) {
                $flexRows.each(function () {
                    var $flexCols = $(this).find('.fc--col--row');
                    var $this = $(this);
                    if ($flexCols.length) {
                        $flexCols.each(function () {
                            var $layout = $(this).data('layout');
                            $this.attr('data-layout', $layout);
                        });
                    }
                })
            }

            var $fcSliders = $('.fc--layout--galerie .wrap');
            if ($fcSliders.length) {
                $fcSliders.each(function () {
                    var $sliderSlides = $('.slider', $(this));
                    $sliderSlides.slick('unslick');
                    $sliderSlides.slick({
                        accessibility: false,
                        arrows: true,
                        prevArrow: '<button class="fc--layout--galerie--arrow fc--layout--galerie--arrow--prev">' + $prevArrow + '</button>',
                        nextArrow: '<button class="fc--layout--galerie--arrow fc--layout--galerie--arrow--next">' + $nextArrow + '</button>',
                        centerMode: true,
                        centerPadding: 0,
                        fade: false,
                        cssEase: 'ease',
                        dots: false,
                        edgeFriction: 0,
                        focusOnSelect: true,
                        infinite: true,
                        slidesToScroll: 1,
                        slidesToShow: 3,
                        speed: 1000,
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 1
                                }
                            }
                        ]
                    });
                });
            }

            if ($fcNav.length && window.matchMedia("(max-width: 1024px)").matches) {
                $('.fc--nav--title').on('click', function () {
                    fvNavMobile();
                });
            }
        }
    }

    $(window).resize(function() {
        fvNavMobile();
    }).resize();

    function fvNavMobile() {
        $('.fc--nav--title').on('click', function () {
            $fcNav.toggleClass('fc--nav--active');
        });
    }

    var $recruitmentJob = $('.recruitment--job')
    if ($recruitmentJob.length) {
        $('.recruitment--job--more', $recruitmentJob).on('click', function() {
            var $dotdotdot = $(this).closest('.recruitment--job').find('.recruitment--job--text--truncated');
            var $txt = $(this).closest('.recruitment--job').find('.recruitment--job--text--original');
            if ($dotdotdot.css('display') === 'none') {
                $txt.hide();
                $dotdotdot.fadeToggle();
            } else {
                $dotdotdot.hide();
                $txt.fadeToggle();
            }
            var $top = $(this).scrollTop();
            if ($(this).hasClass('active')) {
                $htmlBody.animate({
                    scrollTop: $top
                }, 500);
            }
            $(this).toggleClass('active');
        });
    }

    var $wcfFile = $('.wcf--field--file');
    if ($wcfFile.length) {
        $wcfFile.wrapAll('<div class="wcf--fields--files" />');
        var $label = $('.contact--form').data('label');
        $('.wcf--fields--files').prepend('<div class="wcf--fields--files--label">' + $label + '</div>');
    }

    var $archiveProduct = $('.archive--product--body');
    if ($archiveProduct.length) {
        var $productTerms = $('.archive--product--terms', $archiveProduct);
        if ($productTerms.length) {
            var $keyMain = $productTerms.attr('data-initial');
            var firstInit = true;
            $productTerms.on('init', function() {
                var $link = $('.slick-current .archive--product--term').attr('data-url');
                $('.archive--product--content--cta .cta--btn--link').attr('href', $link);
            });

            $productTerms.on('beforeChange', function() {
                if (firstInit) {
                    firstInit = false;
                    return;
                }
                $('.archive--product--step--2, .archive--product--step--3').hide();
            });


            $productTerms.slick({
                accessibility: false,
                arrows: $arrows,
                prevArrow: '<button class="archive--product--terms--arrow archive--product--terms--arrow--prev">' + $prevArrow + '</button>',
                nextArrow: '<button class="archive--product--terms--arrow archive--product--terms--arrow--next">' + $nextArrow + '</button>',
                centerMode: true,
                centerPadding: 0,
                fade: false,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });

            $productTerms.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var $link = $('.slick-slide[data-slick-index="' + nextSlide + '"] .archive--product--term').attr('data-url');
                $('.archive--product--content--cta .cta--btn--link').attr('href', $link);
            });

            $productTerms.slick('slickGoTo', $keyMain, true);

        }

        var $productNavItem = $('.archive--product--step');
        $productNavItem.each(function () {
            var $target = $(this).attr('data-target');
            var $element = $('.archive--product--nav--item[data-target="' + $target + '"]');
            var $min = parseInt($(this).offset().top, 10);
            var $max = parseInt($min + $(this).outerHeight() - 2, 10);
            $(this).scrollspy({
                min: $min,
                max: $max,
                buffer: $window.height() / 2,
                onEnter: function onEnter() {
                    $element.addClass('archive--product--nav--item--active');
                },
                onLeave: function onLeave() {
                    $element.removeClass('archive--product--nav--item--active');
                }
            });
        });
        $('.archive--product--step--3').removeClass('archive--product--step--active')
        $('.archive--product--item').removeClass('archive--product--item--active');

        var $step2 = $('.archive--product--step--2');
        var $step3 = $('.archive--product--step--3');
        var $navStep = $('.archive--product--nav');
        var $categoryItem = $('.archive--product--category--item', $archiveProduct);
        var $productItem = $('.archive--product--item', $archiveProduct);
        showProducts();
        $categoryItem.on('click', function () {
            var $type = $(this).data('type');
            var $name = $(this).data('name');
            var $publicName = $step2.data('public-name');

            $categoryItem.removeClass('archive--product--category--item--active');
            $(this).addClass('archive--product--category--item--active');
            var $productItemType = $('.archive--product--item[data-type="' + $name + '"]', $archiveProduct);
            $productItem.removeClass('archive--product--item--active');
            $productItemType.addClass('archive--product--item--active');
            $('.archive--product--content--main--header--1', $step3).html($publicName + ' ' + $name);
            $('.archive--product--nav--item--3', $navStep).attr('data-target', 'archive--product--step--3')
            showProducts();
            $('.archive--product--next--nav--item').trigger('click');
        })

        var $taxRace = $('body.tax-race');
        if ($taxRace.length) {
            var $top = $('.archive--product--content--main').offset().top - $header.height();
            $htmlBody.animate({
                scrollTop: $top
            }, 500);
        }
    }

    var $scrollTo = $('li[data-target]');
    if($scrollTo.length){
        $scrollTo.on('click', function (){
            var $margin = 0;
            var $target = $('div[data-target="' + $(this).attr('data-target') + '"');
            if ($('.page--post-content').length) {
                if (!$target.closest('.fc--row').hasClass('aos-animate')) {
                    $margin = 100;
                    if (window.matchMedia("(max-width: 1024px)").matches) {
                        $margin = 200;
                    }
                } else {
                    if (window.matchMedia("(max-width: 1024px)").matches) {
                        $margin = 100;
                    }
                }
            }
            var $offsetTop =  $target.offset().top - $header.height() - $('.header--svg').height() - $margin;
            if ($fcNav.length) {
                $fcNav.removeClass('fc--nav--active');
            }
            $htmlBody.animate({
                scrollTop: $offsetTop
            }, 800);
        });
    }

    var $singleProduct = $('.single--product--body');
    if ($singleProduct.length) {
        var $productSlide = $('.single--product--images--items');
        var $productNavSlide = $('.single--product--images--nav');
        if ($productSlide.length) {
            $productSlide.slick({
                accessibility: false,
                arrows: false,
                prevArrow: '<button class="single--product--images--arrow single--product--images--arrow--prev">' + $prevArrow + '</button>',
                nextArrow: '<button class="single--product--images--arrow single--product--images--arrow--next">' + $nextArrow + '</button>',
                appendArrows: $appendArrowsDots,
                centerMode: false,
                centerPadding: 0,
                fade: true,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                speed: 1000,
                asNavFor: '.single--product--images--nav'
            });
            $productNavSlide.slick({
                accessibility: false,
                arrows: false,
                prevArrow: '<button class="single--product--images--arrow single--product--images--arrow--prev">' + $prevArrow + '</button>',
                nextArrow: '<button class="single--product--images--arrow single--product--images--arrow--next">' + $nextArrow + '</button>',
                appendArrows: $appendArrowsDots,
                centerMode: false,
                centerPadding: 0,
                fade: false,
                cssEase: 'ease',
                dots: false,
                edgeFriction: 0,
                focusOnSelect: true,
                infinite: true,
                slidesToScroll: 1,
                slidesToShow: 5,
                speed: 1000,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ],
                asNavFor: '.single--product--images--items'
            });
        }

        var $highchart = $('.single--product--highchart', $singleProduct).data('highchart');
        console.log($highchart);
        if ($highchart.hasOwnProperty('male')) {
            var $dataMale = [];
            var $male = $highchart.male.data;
            for (var i in $male) {
                var $taskParent = {
                    x: parseFloat($male[i].x),
                    y: parseFloat($male[i].y),
                };
                $dataMale.push($taskParent);
            }
            $highchart.male.data = $dataMale;

            if ($highchart.hasOwnProperty('femelle')) {
                var $dataFemelle = [];
                var $femelle = $highchart.femelle.data;
                for (var i in $femelle) {
                    var $taskParent = {
                        x: parseFloat($femelle[i].x),
                        y: parseFloat($femelle[i].y),
                    };
                    $dataFemelle.push($taskParent);
                }
                $highchart.femelle.data = $dataFemelle;

                if ($highchart.hasOwnProperty('mixte')) {
                    var $dataMixte = [];
                    var $mixte = $highchart.mixte.data;
                    for (var i in $mixte) {
                        var $taskParent = {
                            x: parseFloat($mixte[i].x),
                            y: parseFloat($mixte[i].y),
                        };
                        $dataMixte.push($taskParent);
                    }
                    $highchart.mixte.data = $dataMixte;
                    var $series = [{
                        name: $highchart.male.name,
                        data: $dataMale,
                        color: $highchart.male.color,
                    }, {
                        name: $highchart.femelle.name,
                        data: $dataFemelle,
                        color: $highchart.femelle.color,
                    }, {
                        name: $highchart.mixte.name,
                        data: $dataMixte,
                        color: $highchart.mixte.color,
                    }];
                } else {
                    var $series = [{
                        name: $highchart.male.name,
                        data: $dataMale,
                        color: $highchart.male.color,
                    }, {
                        name: $highchart.femelle.name,
                        data: $dataFemelle,
                        color: $highchart.femelle.color,
                    }];
                }
            } else {
                var $series = [{
                    name: $highchart.male.name,
                    data: $dataMale,
                    color: $highchart.male.color,
                }];
            }
        } else if ($highchart.hasOwnProperty('femelle')) {
            var $dataFemelle = [];
            var $femelle = $highchart.femelle.data;
            for (var i in $femelle) {
                var $taskParent = {
                    x: parseFloat($femelle[i].x),
                    y: parseFloat($femelle[i].y),
                };
                $dataFemelle.push($taskParent);
            }
            $highchart.femelle.data = $dataFemelle;

            if ($highchart.hasOwnProperty('mixte')) {
                var $dataMixte = [];
                var $mixte = $highchart.mixte.data;
                for (var i in $mixte) {
                    var $taskParent = {
                        x: parseFloat($mixte[i].x),
                        y: parseFloat($mixte[i].y),
                    };
                    $dataMixte.push($taskParent);
                }
                $highchart.mixte.data = $dataMixte;
                var $series = [{
                    name: $highchart.femelle.name,
                    data: $dataFemelle,
                    color: $highchart.femelle.color,
                }, {
                    name: $highchart.mixte.name,
                    data: $dataMixte,
                    color: $highchart.mixte.color,
                }];
            } else {
                var $series = [{
                    name: $highchart.femelle.name,
                    data: $dataFemelle,
                    color: $highchart.femelle.color,
                }];
            }
        } else if ($highchart.hasOwnProperty('mixte')) {
            var $dataMixte = [];
            var $mixte = $highchart.mixte.data;
            for (var i in $mixte) {
                var $taskParent = {
                    x: parseFloat($mixte[i].x),
                    y: parseFloat($mixte[i].y),
                };
                $dataMixte.push($taskParent);
            }
            $highchart.mixte.data = $dataMixte;
            var $series = [{
                name: $highchart.mixte.name,
                data: $dataMixte,
                color: $highchart.mixte.color,
            }];
        } else if ($highchart.hasOwnProperty('all')) {
            var $dataAll = [];
            var $all = $highchart.all.data;
            for (i in $all) {
                var $taskParent = {
                    x: parseFloat($all[i].x),
                    y: parseFloat($all[i].y),
                };
                $dataAll.push($taskParent);
            }
            $highchart.all.data = $dataAll;

            var $series = [{
                name: $highchart.all.name,
                data: $dataAll,
                color: $highchart.all.color,
            }];
        }

        new Highcharts.chart({
            chart: {
                backgroundColor: '#ffffff',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'spline',
                renderTo: 'single--product--highchart',
            },
            title: {
                text: $highchart.title,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                allowDecimals: false,
                className: 'highcharts--axis--x',
                lineColor: '#bbbbbb',
                lineWidth: 2,
                min: 0,
                startOnTick: true,
                tickColor: '#bbbbbb',
                tickWidth: 2,
                // tickInterval: 5,
                title: {
                    text: $highchart.xAxis,
                    margin: 30,
                }
            },
            yAxis: {
                allowDecimals: false,
                alternateGridColor: '#f9f9f9',
                className: 'highcharts--axis--y',
                gridLineColor: '#bbbbbb',
                gridLineWidth: 2,
                labels: {
                    format: '{value:.0f}'
                },
                lineColor: '#bbbbbb',
                lineWidth: 0,
                max: $highchart.yMax,
                maxPadding: 0,
                tickColor: '#bbbbbb',
                tickWidth: 2,
                title: {
                    text: $highchart.yAxis,
                    margin: 30,
                },
                showLastLabel: true,
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + ' ' + $highchart.scale + '</b> : ' + this.y + $highchart.suffixe;
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'top',
                enabled: $highchart.legend,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                    }
                }
            },
            series: $series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500,
                    },
                }],
            },
        });
    }

    if ($gmap.length) {
        var macarte = null;
        var markerClusters;
        var $sessionStorage = {};

        var $singleImplantation = $('body.single-implantation');
        if ($singleImplantation.length) {
            var $top = $('.implantations--item').offset().top - $header.height() - $('.header--svg').height();
            $htmlBody.animate({
                scrollTop: $top
            }, 500);
        }

        var $implantations = $('.implantations--body');
        if ($implantations.length) {
            var $formImplantations = $('.implantations--form', $implantations);
            if ($formImplantations.length) {
                if (sessionStorage.getItem('zone')) {
                    checkZone();
                }

                $('.implantations--form--select', $formImplantations).on('click', function () {
                    $('.implantations--form--field--select').toggleClass('implantations--form--field--select--active');
                });

                var $selectImplantations = $('.implantations--form--select span', $formImplantations);
                var $optionImplantations = $('.implantations--form--select--option', $formImplantations);
                $optionImplantations.on('click', function () {
                    var $value = $(this).text();
                    var mapLat = $(this).data('lat');
                    var mapLng = $(this).data('lng');
                    var mapZoom = $(this).data('zoom');
                    var mapLatLng = {lat: mapLat, lng: mapLng};
                    macarte.flyTo(mapLatLng, mapZoom);
                    sessionStorage.setItem('zone', $value);
                    $selectImplantations.text($value);
                    $('.implantations--form--field--select').toggleClass('implantations--form--field--select--active');
                });
            }
        }

        var $itemLegend = $('.implantations--legend--item');
        if ($itemLegend.length) {
            if (sessionStorage.getItem('implantation')) {
                removeDisableLegends();
            }
            checkLegends();

            $itemLegend.on('click', function (e) {
                var $legend = $(this).data('legend');
                $('.implantations--legend--item.implantations--legend--item--' + $legend).toggleClass('active');
                $('.implantation.' + $legend).toggleClass('active');
                checkLegends();
                // macarte.remove();
                initMapImplantation();
                e.preventDefault();
            });
        }

        initMapImplantation();

        // Fonction d'initialisation de la carte
        function initMapImplantation() {
            if ($gmap.length) {
                $gmap.each(function() {
                    var $gmap = $(this);
                    var gmapContainer = $('.gmap--container', $gmap)[0];
                    var $data = $('.gmap--markers', $gmap);
                    var mapLat = $gmap.data('lat');
                    var mapLng = $gmap.data('lng');
                    var mapLatLng = {lat: mapLat, lng: mapLng};
                    var mapZoom = $gmap.data('zoom');
                    var $pins = $('.gmap--marker.active', $data);
                    // Leaflet ne récupère pas les cartes (tiles) sur un serveur par défaut. Nous devons lui préciser où nous souhaitons les récupérer. Ici, openstreetmap.fr
                    // var url = 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png';
                    var url = 'https://api.maptiler.com/maps/c447960e-3eb8-4629-acc4-9ceb2c0fae91/{z}/{x}/{y}.jpg?key=iqwgkS4lQhJGOtfVGy5V'
                    var tiles = L.tileLayer(url, {
                        // Il est toujours bien de laisser le lien vers la source des données
                        attribution: 'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
                        minZoom: 3,
                        maxZoom: 20,

                    });

                    console.info($pins);

                    // Créer l'objet "macarte" et l'insèrer dans l'élément HTML qui a l'ID "map"
                    if (!macarte) {
                        macarte = L.map(gmapContainer, {gestureHandling: true, center: mapLatLng, zoom: mapZoom, layers: [tiles]});
                    }
                    // else {
                    //     macarte.fitBounds(myFGMarker.getBounds());
                    // }
                    macarte.zoomControl.setPosition('bottomleft');

                    markerClusters = new L.markerClusterGroup(); // Nous initialisons les groupes de marqueurs

                    updateMarkers($pins);

                    macarte.fitBounds(markerClusters.getBounds());
                });
            }
        }
        function removeDisableLegends() {
            var $storage = sessionStorage.getItem('implantation');
            $storage = JSON.parse($storage);
            var $legends = $storage.implantations.split(',');
            $itemLegend.each(function() {
                var $legend = $(this).data('legend');
                if (jQuery.inArray($legend, $legends) == -1) {
                    $('.implantations--legend--item--' + $legend).removeClass('active');
                    $('.implantation.' + $legend).removeClass('active');
                }
            });
        }

        function checkZone() {
            var $zone = sessionStorage.getItem('zone');
            var $selectImplantations = $('.implantations--form--select span', $formImplantations);
            var $optionImplantations = $('.implantations--form--select--option', $formImplantations);
            $optionImplantations.each(function () {
                if ($(this).text() == $zone) {
                    var mapLat = $(this).data('lat');
                    var mapLng = $(this).data('lng');
                    var mapZoom = $(this).data('zoom');
                    $gmap.attr('data-lat', mapLat);
                    $gmap.attr('data-lng', mapLng);
                    $gmap.attr('data-zoom', mapZoom);
                    $selectImplantations.text($zone);
                }
            });
        }

        function checkLegends() {
            var $storageLegends = '';
            $itemLegend.each(function() {
                var $legend = $(this).data('legend');
                if ($(this).hasClass('active')) {
                    $storageLegends = $storageLegends + $legend + ',';
                    $('.implantation.' + $legend).addClass('active');
                }
            });
            if ($storageLegends.length) {
                $storageLegends = $storageLegends.substr(0, $storageLegends.length - 1);
            }
            $sessionStorage = {
                'implantations': $storageLegends,
            }
            $sessionStorage = JSON.stringify($sessionStorage);
            sessionStorage.setItem('implantation', $sessionStorage);
        }

        function updateMarkers($pins) {
            $pins.each(function(){
                var $pin = $(this);
                var pinLat = $pin.data('lat');
                var pinLng = $pin.data('lng');
                var infoWindowContent = $($pin).html();
                var pinUrl = $pin.data('pin');
                var myIcon = L.icon({
                    iconUrl: pinUrl,
                    iconSize: [40, 51],
                    iconAnchor: [20, 51],
                });

                var marker = new L.marker([pinLat, pinLng], { icon: myIcon }); // pas de addTo(macarte), l'affichage sera géré par la bibliothèque des clusters
                marker.bindPopup(infoWindowContent);
                markerClusters.addLayer(marker);
            });
            macarte.eachLayer(function (layer) {
                if (!!layer.toGeoJSON) {
                    macarte.removeLayer(layer);
                }
            });
            macarte.addLayer(markerClusters);
        }

    }

    function animCounter(element) {
        console.info('ANIM COUNTER');
        var $item = $(element);
        $item.each(function () {
            var $start = $(this).attr('data-start');
            $(this).prop('Counter', $start).animate({
                Counter: $(this).text()
            }, {
                duration: 3000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });
    }

    function showProducts() {
        var $productItems = $('.archive--product--item--active');
        var $step3 = $('.archive--product--step--3');
        $step3.removeClass('archive--product--step--active');
        if ($productItems.length) {
            $step3.addClass('archive--product--step--active');
        }
    }

    $imgCover = $('.img--cover');
    if ($imgCover.length) {
        objectFitPolyfill($('img', $imgCover));
    }
});