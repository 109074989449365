$(document).ready(function(){
    var $willowForm = $('.layout-formulaire');
    if($willowForm.length) {
        if(document.addEventListener){
            document.addEventListener('invalid', function(e){
                $(e.target).each(function(){
                    var $wrapper = $(this).closest('.wcf-field');
                    $wrapper.removeClass('valid');
                    $wrapper.addClass('error');
                });
            }, true);
            document.addEventListener('valid', function(e){
                $(e.target).each(function(){
                    var $wrapper = $(this).closest('.wcf-field');
                    $wrapper.removeClass('error');
                    $wrapper.addClass('valid');
                });
            }, true);
        }
    }
});